'use strict'

###*
 # @ngdoc object
 # @name mundoAdmin
 # @description

###
angular
  .module 'mundoAdmin', [
    'ui.router',
    'mundoAdmin.dispatch',
    'mundoAdmin.assets',
    'mundoAdmin.incidents',
    'mundoAdmin.contacts',
    'mundoAdmin.locations',
    'mundoAdmin.devices',
    'mundoAdmin.units',
    'mundoAdmin.tasks',
    'mundoAdmin.notifications',
    'mundoAdmin.home'
  ]
  .config (
    formlyConfigProvider
  ) ->
    formlyConfigProvider.setType(
      name: 'autocomplete'
      templateUrl: 'mundo-admin/views/autocomplete.tpl.html'
      defaultOptions:
        templateOptions:
          disabled: 'false'
          nocache: 'false'
          placeholder: ''
          options: []
      controller:
        [
          '$scope'
          ($scope) ->
            $scope.getValue = (item, key) ->
              if !item
                return undefined

              keys = key.split('\.')
              if keys.length == 1
                return item[key]
              else
                current = keys[0]
                return $scope.getValue(item[current], key.slice(current.length+1))

            $scope.match = (value) ->
              search = $scope.getValue(value, $scope.to.displayValue)
              return search.toLowerCase().indexOf($scope.searchText.toLowerCase()) >= 0

        ]

    )
